import { AuthConfig } from 'angular-oauth2-oidc/public_api';
import { environment } from '../environments/environment';

/**
 * private interface for appSettings
 */
interface AppSettings {
  version: string;
  release: string;
  stage: string;
  isProduction: boolean;
  endpoints: {
    assets: {
      assets: string;
      priceAlerts: string;
      transactions: string;
      overviews: string;
    },
    newsFeedArticles: {
      newsFeedArticles: string;
    }
    masterData: {
      currencies: string;
      countries: string;
      enumValues: string;
      keywords: string;
      statusValues: string;
    },
    messaging: {
      channelRegistrations: string;
    }
  };
  authentication: {
    oidc: AuthConfig;
  };
}

export const appSettings: AppSettings = {
  version: '1.3.0',
  release: 'Release 28.04.2024',
  stage: environment.stage,
  isProduction: environment.production,
  authentication: {
    oidc: {
      issuer: environment.oidc.authority + '/',
      redirectUri: environment.baseUrls.uiUrl,
      postLogoutRedirectUri: environment.baseUrls.uiUrl,
      clientId: environment.oidc.clientId,
      scope: environment.oidc.scope,
      silentRefreshRedirectUri: environment.baseUrls.uiUrl + '/silent-refresh.html',
      clearHashAfterLogin: false,
      customTokenParameters: ['app_metadata', 'user_metadata', 'AccountId', 'email', 'name', 'sub', 'TenantId', 'PreferredLanguage', 'UserAccountGuid', 'AccountName'],
      requireHttps: environment.oidc.requireHttps
    }
  },
  endpoints: {
    assets: {
      assets: environment.baseUrls.assetsUrl + '/assets/',
      priceAlerts: environment.baseUrls.assetsUrl + '/assets/:assetId/pricealerts/',
      transactions: environment.baseUrls.assetsUrl + '/assets/:assetId/transactions/',
      overviews: environment.baseUrls.assetsUrl + '/overviews/'
    },
    newsFeedArticles: {
      newsFeedArticles: environment.baseUrls.assetsUrl + '/newsfeedarticles/'
    },
    masterData: {
      currencies: environment.baseUrls.assetsUrl + '/currencies/',
      countries: environment.baseUrls.assetsUrl + '/countries/',
      enumValues: environment.baseUrls.assetsUrl + '/enumvalues/',
      keywords: environment.baseUrls.assetsUrl + '/keywords/',
      statusValues: environment.baseUrls.assetsUrl + '/statusvalues/'
    },
    messaging: {
      channelRegistrations: environment.baseUrls.assetsUrl + '/messagingchannelregistrations/'
    }
  }
};
