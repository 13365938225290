import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BannerMessageLevel } from '../../enums';
import { BannerMessage, BannerMessageError } from '../../models';
import { TranslationService } from '../../services';
import { iconSet } from '../../constants';

@Component({
  selector: 'lib-message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.scss']
})
export class MessageBannerComponent {

  private _message: BannerMessage | undefined;
  private _closeTimer: any;

  public iconSet = iconSet;
  public messageLevels = BannerMessageLevel;
  public showMessage = false;

  @Input()
  public borderBottomRadius = true;

  @Input()
  public set message(message: BannerMessage | undefined) {
    this._message = message
      ? this.generateMessage(message)
      : undefined;
  }
  public get message(): BannerMessage | undefined {
    return this._message;
  }

  @Output()
  public messageChange = new EventEmitter<BannerMessage | undefined>();

  constructor(
    private _translationService: TranslationService
  ) { }

  public close(): void {
    this.showMessage = false;

    if (this._closeTimer) {
      clearInterval(this._closeTimer);
    }

    setTimeout(() => {
      this._message = undefined;
      this.messageChange.emit(undefined);
    }, 300);
  }

  private generateMessage(message: BannerMessage): BannerMessage {
    if (!message.level) {
      message.level = BannerMessageLevel.error;
    }

    switch (message.level) {
      case BannerMessageLevel.error:
        message.icon = iconSet.error;
        break;
      case BannerMessageLevel.critical:
        message.icon = iconSet.criticalError;
        break;
    }

    if (message.fields) {
      const errors = message.fields
        .filter(f => f.errors);
      const errorCount = errors
        .map(f => f.errors)
        .reduce((p, c, _) => {
          if (Object.entries(c).some(e => e[1] === true || (!!e[1] && typeof e[1] === 'object'))) {
            p['push'](c);
          }

          return p;
        }, [])['length'];
      const field = message.fields
        .filter(f => f.errors)
        .find(f => Object.entries(f.errors).some(e => e[1] === true || (!!e[1] && typeof e[1] === 'object')));
      if (field) {
        this.requiredMessage(field, message, errorCount);
        this.emailMessage(field, message, errorCount);
        this.urlMessage(field, message, errorCount);
        this.minValue(field, message, errorCount);
        this.maxValue(field, message, errorCount);
        this.minLength(field, message, errorCount);
        this.maxLength(field, message, errorCount);
        this.decimals(field, message, errorCount);
        this.datetime(field, message, errorCount);
        this.atLeastOneSelectionMessage(field, message, errorCount);
        this.atLeastOneInputMessage(field, message, errorCount);
        this.notUniqueMessage(field, message, errorCount);
        this.guidMessage(field, message, errorCount);
      }
    }

    this.showMessage = true;
    this.startClosingTimer();

    return message;
  }

  private startClosingTimer(): void {
    this._closeTimer = setTimeout(() => {
      this.close();
    }, 5000);
  }

  private requiredMessage(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'required' && e[1] === true)) {
      message.title = this._translationService.translate('NotAllRequiredFieldsFilled', 'validation');
      message.text1 = this._translationService.translate('FieldNotFilled', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private emailMessage(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'email' && e[1] === true)) {
      message.title = this._translationService.translate('WrongPattern', 'validation');
      message.text1 = this._translationService.translate('WrongPatternFormatEmail', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private urlMessage(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'url')) {
      message.title = this._translationService.translate('WrongPattern', 'validation');
      message.text1 = this._translationService.translate('WrongPatternFormat', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private minValue(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'minValue' && e[1] === true)) {
      message.title = this._translationService.translate('InvalidValuesFilledInNumericFields', 'validation');
      message.text1 = this._translationService.translate('InvalidValue', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private maxValue(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'maxValue' && e[1] === true)) {
      message.title = this._translationService.translate('InvalidValuesFilledInNumericFields', 'validation');
      message.text1 = this._translationService.translate('InvalidValue', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private minLength(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'minlength' && e[1])) {
      message.title = this._translationService.translate('NotEnoughCharacters', 'validation', field.errors['minlength'].requiredLength);
      message.text1 = field.customErrorMessage || this._translationService.translate('InvalidValue', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private maxLength(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'maxlength' && e[1])) {
      message.title = this._translationService.translate('MoreThanCharacters', 'validation', field.errors['maxLength'].actualLength, field.errors['maxlength'].requiredLength);
      message.text1 = field.customErrorMessage || this._translationService.translate('InvalidValue', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private datetime(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'date' && e[1])) {
      message.title = this._translationService.translate('InvalidDate', 'validation', field.errors['date'].value);
      message.text1 = field.customErrorMessage || this._translationService.translate('InvalidValue', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    } else if (Object.entries(field.errors).some(e => e[0] === 'dateTime' && e[1])) {
      message.title = this._translationService.translate('InvalidDateTime', 'validation', field.errors['dateTime'].value);
      message.text1 = field.customErrorMessage || this._translationService.translate('InvalidValue', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    } else if (Object.entries(field.errors).some(e => e[0] === 'time' && e[1])) {
      message.title = this._translationService.translate('InvalidTime', 'validation', field.errors['time'].value);
      message.text1 = field.customErrorMessage || this._translationService.translate('InvalidValue', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private decimals(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'decimalplaces' && e[1])) {
      message.title = this._translationService.translate('ToManyDecimalPlaces', 'validation', field.errors['decimalplaces'].decimals);
      message.text1 = field.customErrorMessage || this._translationService.translate('InvalidValue', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private atLeastOneSelectionMessage(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'atLeastOneSelection' && e[1] === true)) {
      message.title = this._translationService.translate('NotAllRequiredFieldsFilled', 'validation');
      message.text1 = this._translationService.translate('AtLeastOneSelectionRequired', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private atLeastOneInputMessage(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'atLeastOneInput' && e[1] === true)) {
      message.title = this._translationService.translate('NotAllRequiredFieldsFilled', 'validation');
      message.text1 = this._translationService.translate('AtLeastOneInputRequired', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private notUniqueMessage(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    if (Object.entries(field.errors).some(e => e[0] === 'notUnique' && e[1] === true)) {
      message.title = this._translationService.translate('NotAllRequiredFieldsFilled', 'validation');
      message.text1 = this._translationService.translate('NotUnique', 'validation', field.translation);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }

  private guidMessage(field: BannerMessageError, message: BannerMessage, errorCount: number): void {
    const error = Object.entries(field.errors).find(e => e[0] === 'guid');
    if (error) {
      message.title = this._translationService.translate('NotAllRequiredFieldsFilled', 'validation');
      message.text1 = this._translationService.translate('NotGuid', 'validation', field.translation, error[1].value);
      message.text2 = errorCount > 1
        ? errorCount > 2
          ? this._translationService.translate('FurtherErrors', 'validation', errorCount - 1)
          : this._translationService.translate('FurtherError', 'validation', errorCount - 1)
        : null;
    }
  }
}
