import { NgModule } from '@angular/core';
import { AssetsSharedModule } from '@assets/shared';

import { AppShellComponent } from './components/app-shell/app-shell.component';

@NgModule({
  declarations: [
    AppShellComponent
  ],
  imports: [
    AssetsSharedModule
  ],
  exports: [
    AppShellComponent
  ]
})
export class CoreModule { }
