<lib-eshava-single-dialog [title]="dialogTitle"
                          [dialogHeight]="'dynamic'"
                          [dialogWidth]="'small'">
  <div content
       class="dialog-content">
    <p>{{dialogMessage}}</p>
  </div>
  <div banner>
    <lib-message-banner [(message)]="errorMessage"></lib-message-banner>
  </div>
  <div indicator>
    <lib-component-loading-indicator [isVisible]="showLoadingIndicator"></lib-component-loading-indicator>
  </div>
  <div footer>
    <button type="button"
            class="eshava-btn eshava-btn-primary"
            (click)="confirm()">{{primaryButtonTitle}}</button>
    <button type="button"
            class="eshava-btn eshava-btn-secondary ms-2"
            (click)="closeDialog()">{{secondaryButtonTitle}}</button>
  </div>
</lib-eshava-single-dialog>
