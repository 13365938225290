<div class="app-shell">
  <div class="app-shell-header">
    <div class="app-shell-navbar-left"> </div>
    <ng-container *ngFor="let link of links">
      <a class="app-shell-navbar-link"
         *ngIf="!link.data?.isHidden"
         [routerLink]="generateLinkPath(link.path)"
         [ngClass]="{'active': isActiveLink(link.originalPath || link.path)}"
         title="{{ link.data?.titleKey | translate: 'navigation' }}"> <span>{{ link.data?.titleKey | translate: 'navigation' }}</span>
      </a>
    </ng-container>
    <ng-container *ngIf="!appSettings.isProduction">
      <div *ngIf="!isDarkTheme"
           class="app-shell-navbar-link theme ms-auto me-4"
           (click)="applyDarkTheme()">Dark</div>
      <div *ngIf="isDarkTheme"
           class="app-shell-navbar-link theme ms-auto me-4"
           (click)="applyLightTheme()">Light</div>
    </ng-container>
    <div class="app-sign-out me-4"
         [class.ms-auto]="appSettings.isProduction"
         title="{{username}} &ndash; {{ 'SignOut' | translate:'assets' }}"
         (click)="signOut()">
      <svg-icon *ngIf="!userThumbnail"
                src="{{iconSet.arrowRight}}"
                class="eshava-assets-svg"></svg-icon>
      <img class="user-thumbnail"
           *ngIf="userThumbnail"
           [src]="userThumbnail">
    </div>
  </div>
  <div class="app-shell-content">
    <div class="app-shell-sidebar">
      <div class="list-group list-group-flush">
        <ng-container *ngFor="let item of menuItems">
          <a class="list-group-item list-group-item-action"
             [routerLink]="['/',item.path]"
             routerLinkActive="active"
             title="{{ item.titleKey | translate:'navigation' }}">
            <svg-icon src="{{item.icon}}"
                      class="eshava-assets-svg"></svg-icon>
          </a>
        </ng-container>
      </div>
    </div>
    <div class="app-shell-content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<lib-context-menu></lib-context-menu>