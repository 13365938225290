import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppShellComponent } from './modules/core/components/app-shell/app-shell.component';
import { AuthGuard, iconSet } from '@assets/shared'

const routes: Routes = [{
  path: '',
  canActivate: [AuthGuard],
  component: AppShellComponent,
  children: [{
    path: 'dashboards',
    loadChildren: () => import('@assets/dashboards').then(m => m.AssetsDashboardsModule)
  }, {
    path: 'assets',
    loadChildren: () => import('@assets/assets').then(m => m.AssetsAssetsModule)
  }]
},
// {
//   path: '403',
//   component: ForbiddenComponent
// },
{
  path: '**',
  redirectTo: 'dashboards',
  pathMatch: 'full'
}];

export const mainNavigationRoutes: Routes = [{
  path: 'dashboards',
  data: {
    titleKey: 'Dashboards',
    isMainNavigation: true,
    icon: iconSet.chartSimpleSolid,
  }
}, {
  path: 'assets',
  data: {
    titleKey: 'Assets',
    isMainNavigation: true,
    icon: iconSet.listSolid,
  }
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
