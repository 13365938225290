export const iconSet = {
  arrowRight: 'assets/icons/arrow-right-from-bracket-solid.svg',
  assetBuy: 'assets/icons/download-solid.svg',
  assetDividends: 'assets/icons/clock-regular.svg',
  assetSell: 'assets/icons/upload-solid.svg',
  cancel: 'assets/icons/xmark-solid.svg',
  caretLeft: 'assets/icons/caret-left-solid.svg',
  caretRight: 'assets/icons/caret-right-solid.svg',
  chartLine: 'assets/icons/chart-line-solid.svg',
  chartSimpleSolid: 'assets/icons/chart-simple-solid.svg',
  copy: 'assets/icons/copy-regular.svg',
  criticalError: 'assets/icons/fire-solid.svg',
  delete: 'assets/icons/trash-can-solid.svg',
  edit: 'assets/icons/pen-to-square-solid.svg',
  error: 'assets/icons/xmark-solid.svg',
  favorite: 'assets/icons/bookmark-regular.svg',
  listSolid: 'assets/icons/list-solid.svg',
  loading: 'assets/icons/circle-notch-solid.svg',
  performanceNegative: 'assets/icons/arrow-trend-down-solid.svg',
  performancePositive: 'assets/icons/arrow-trend-up-solid.svg',
  reset: 'assets/icons/rotate-left-solid.svg',
  view: 'assets/icons/eye-solid.svg'
}
