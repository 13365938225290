import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _errorHandler: OAuthResourceServerErrorHandler,
    private _authenticationService: AuthenticationService,
    private _translationService: TranslationService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // this._notificationService.showErrorNotification({
            //   message: this._translationService.translate('HttpAuthenticationErrorDescription', 'core'),
            //   title: this._translationService.translate('HttpAuthenticationErrorTitle', 'core')
            // });
            this._authenticationService.redirectToSignIn('');

            return of(undefined);
          }

          return this._errorHandler.handleError(error);
        })
      );
  }
}
