import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { Subject } from 'rxjs';
import { NavigationLink } from '../models';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _links: NavigationLink[] = [];
  private _linksChanged = new Subject<NavigationLink[]>();
  private _linkClicked = new Subject<NavigationLink>();

  private _subLinks: NavigationLink[] = [];
  private _subLinksChanged = new Subject<NavigationLink[]>();
  private _subLinkClicked = new Subject<NavigationLink>();

  public linksChanged$ = this._linksChanged.asObservable();
  public linkClicked$ = this._linkClicked.asObservable();

  public subLinksChanged$ = this._subLinksChanged.asObservable();
  public subLinkClicked$ = this._subLinkClicked.asObservable();

  constructor(

  ) { }

  public set links(links: NavigationLink[]) {
    this._links = links;
    this._linksChanged.next(links);
  }

  public get links(): NavigationLink[] {
    return this._links;
  }

  public set subLinks(subLinks: NavigationLink[]) {
    this._subLinks = subLinks;
    this._subLinksChanged.next(subLinks);
  }

  public get subLinks(): NavigationLink[] {
    return this._subLinks;
  }

  public click(link: NavigationLink): void {
    this._linkClicked.next(link);
  }

  public setupMainNavigation(routes: Routes, baseUrl: string): void {
    const newLinks = this.setupSubNavigation(routes, baseUrl);
    this.links = newLinks;
  }

  public setupSubNavigation(childRoutes: Routes, baseUrl: string): NavigationLink[] {
    if (!childRoutes) {
      return [];
    }

    const newLinks: NavigationLink[] = [];
    childRoutes.filter(route => !!route.data).forEach(route => {
      let childBaseRoute = baseUrl + route.path;
      if (childBaseRoute[childBaseRoute.length - 1] !== '/') {
        childBaseRoute += '/';
      }

      newLinks.push({
        path: baseUrl + route.path,
        data: {
          titleKey: (route.data as any)?.titleKey || '',
          description: (route.data as any)?.description || '',
          isHidden: (route.data as any)?.isHidden || false,
        },
        children: this.setupSubNavigation(route.children as Routes, childBaseRoute)
      });
    });
    this.subLinks = newLinks;

    return newLinks;
  }
}
