<div class="eshava-context-menu"
     [class.invisible]="!items || items.length === 0"
     [ngStyle]="{'left.px': xPosition, 'top.px': yPosition}">
  <ng-container *ngFor="let item of items">
    <div class="eshava-context-menu-item"
         [class.main-action]="item.isMainAction"
         [class.disabled]="item.isDisabled"
         [style.color]="!item.isDisabled ? item.color : 'unset'"
         *ngIf="!item.isSeparator"
         (click)="onClickedItem(item)">
      <div class="eshava-context-menu-item-icon">
        <svg-icon *ngIf="item.icon"
                  src="{{item.icon}}"
                  class="eshava-assets-context-menu-svg"
                  [style.fill]="!item.isDisabled ? (item.color||'var(--context-menu-color)') : 'var(--context-menu-color-disabled)'"></svg-icon>
      </div>
      <div class="eshava-context-menu-item-title"
           [title]="item.title">{{ item.title }}</div>
    </div>
    <div class="eshava-context-menu-separator"
         *ngIf="item.isSeparator"></div>
  </ng-container>
</div>