import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import { AuthenticationService, darkTheme, iconSet, lightTheme, MenuItem, MenuService, NavigationLink, NavigationService, ThemeService, TranslationService } from '@assets/shared';
import { Subscription } from 'rxjs';
import { appSettings } from '../../../../../settings/app-settings';

@Component({
  selector: 'assets-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss']
})
export class AppShellComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  private _routeParamsList: Params[] = [];
  private _urlPartsLastRouting: string[] | undefined;

  public iconSet = iconSet;
  public links: NavigationLink[] = [];
  public menuItems: MenuItem[] = [];
  public subMenuItems: MenuItem[] = [];
  public selectedMenuItem: MenuItem | undefined;
  public userThumbnail = '';
  public username = '';
  public isDarkTheme = false;
  public appSettings = appSettings;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _themeService: ThemeService,
    private _menuService: MenuService,
    private _navigationService: NavigationService,
    private _translationService: TranslationService,
    private _authenticationService: AuthenticationService
  ) {

    this._subscriptions.add(
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this._routeParamsList = [];
          this.collectRouteParams(this._activatedRoute.snapshot);

          this.formatAndSetPageTitle(event as NavigationEnd);
        }
      })
    );

    this.isDarkTheme = this._themeService.getTheme() === darkTheme;
    this._subscriptions.add(this._translationService.translationsLoaded$.subscribe(_ => this.afterTranslationLoaded()));
  }

  public ngOnInit(): void {
    this._subscriptions.add(
      this._menuService.menuItemsChanged$.subscribe(menuItems => {
        this.menuItems = menuItems;
      })
    );

    this._subscriptions.add(
      this._navigationService.linksChanged$.subscribe(links => {
        setTimeout(() => {
          this.links = links;
        }, 10);
      })
    );

    this.username = this._authenticationService.getUsername();
    this.userThumbnail = this._authenticationService.getUserThumbnail();
    this.menuItems = (this._menuService.menuItems || []);
    this.links = (this._navigationService.links || []);
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
    }
  }

  public applyDarkTheme(): void {
    this.isDarkTheme = true;
    this._themeService.setTheme(darkTheme, 'dark');
  }

  public applyLightTheme(): void {
    this.isDarkTheme = false;
    this._themeService.setTheme(lightTheme, 'light');
  }

  public signOut(): void {
    this._authenticationService.signOut();
  }

  public isActiveLink(linkPath: string): boolean {
    return this._router.isActive(
      this.generateLinkPath(linkPath), {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored'
    });
  }

  public generateLinkPath(orgLinkPath: string): string {
    let linkPath = orgLinkPath.toString();
    this._routeParamsList.forEach(params => {
      Object.keys(params).forEach(key => {
        linkPath = linkPath.replace(':' + key, params[key].toString());
      });
    });

    return linkPath;
  }

  private afterTranslationLoaded(): void {
    if (this._urlPartsLastRouting) {
      this.setTitle(this._urlPartsLastRouting);
    }
  }

  private collectRouteParams(snapshot: ActivatedRouteSnapshot): void {
    if (snapshot.params) {
      this._routeParamsList.push(snapshot.params);
    }

    snapshot.children.forEach(child => {
      this.collectRouteParams(child);
    });
  }

  private formatAndSetPageTitle(event: NavigationEnd): void {
    const url = event.urlAfterRedirects || event.url;
    const urlParts = url.split('/').filter(part => !!part);
    this._urlPartsLastRouting = urlParts;

    this.setTitle(urlParts);
  }

  private setTitle(urlParts: string[]): void {
    if (urlParts.length === 0) {
      this._titleService.setTitle('Eshava.Assets');

      return;
    }

    let pageTitle = '';
    const module = urlParts[0];
    for (let index = 0; index < urlParts.length; index++) {
      if (isNaN(parseInt(urlParts[index], 10))) {

        const urlPartNames = urlParts[index].split('-');
        let urlPartName = '';
        urlPartNames.forEach(namePart => {
          urlPartName += (namePart[0].toUpperCase() + namePart.substring(1));
        });

        let name = this._translationService.translate('Route' + urlPartName, 'assets-' + module + 's');
        if (!name || name.indexOf('missing') > -1) {
          name = this._translationService.translate(urlPartName, 'assets-' + module + 's');
        }

        if (!name || name.indexOf('missing') > -1) {
          name = this._translationService.translate('Route' + urlPartName, 'navigation');
        }

        if (!name || name.indexOf('missing') > -1) {
          name = this._translationService.translate(urlPartName, 'navigation');
        }

        if (!!name && name.indexOf('missing') === -1) {
          pageTitle += ` | ${name}`;
        }
      }
    }

    pageTitle = pageTitle.substring(3) + ' - Eshava.Assets';
    this._titleService.setTitle(pageTitle);
  }
}
