import { Environment } from './environment.type';

export const environment: Environment = {
  production: false,
  stage: 'development',
  oidc: {
    authority: 'https://dev-cardinal.eshava.de',
    audience: 'https://dev-assets.eshava.de',
    clientId: 'Eshava.UI.Angular.Assets-Dev',
    requireHttps: true,
    scope: 'openid profile email services.api.read'
  },
  baseUrls: {
    assetsUrl: 'https://dev-api.eshava.de/assets',
    cardinalUrl: 'https://dev-cardinal.eshava.de',
    dataStorageUrl: 'https://dev-api.eshava.de/datastorage',
    uiUrl: 'https://dev-assets.eshava.de'
  }
};
