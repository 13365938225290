import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { debounceTime, Subscription } from 'rxjs';
import { EshavaScrollSpyItem } from '../../models';
import { EshavaScrollSpyService } from '../../services';

@Directive({
  selector: '[scrollSpyListItem]'
})
export class EshavaScrollSpyListItemDirective implements OnInit, OnDestroy {

  private _isActive = false;
  private _subscriptions = new Subscription();
  private _intersectionName!: string;

  @Input()
  public scrollSpyListItem!: EshavaScrollSpyItem;

  @Input()
  public set intersectionName(value: string | undefined) {
    this._intersectionName = value || 'intersectionRoot';
  }

  public get intersectionName(): string {
    return this._intersectionName;
  }

  @Input()
  public activeClass = '';

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _scrollSpyService: EshavaScrollSpyService
  ) { }

  public ngOnInit(): void {
    this._subscriptions.add(
      this._scrollSpyService.itemStatusChanged$.subscribe(
        item => {
          if (item.intersectionName === this.intersectionName) {
            this.setClass(item.anchor === this.scrollSpyListItem.anchor);
          }
        }
      )
    );
    this._subscriptions.add(
      this._scrollSpyService.itemStatusChanged$.pipe(debounceTime(500)).subscribe(
        item => {
          if (item.intersectionName === this.intersectionName) {
            this.scrollToActiveItem();
          }
        }
      )
    );
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = new Subscription();
    }
  }

  private setClass(isActive: boolean): void {
    if (isActive === this._isActive) {
      return;
    }

    if (isActive) {
      this._renderer.addClass(this._el.nativeElement, this.activeClass);
    } else {
      this._renderer.removeClass(this._el.nativeElement, this.activeClass);
    }
    this._isActive = isActive;
  }

  private scrollToActiveItem(): void {
    if (this._isActive) {
      // check if Listitem is fully visible
      const elementPosition = this._el.nativeElement.offsetTop + this._el.nativeElement.offsetHeight;
      const scrollingContainer = this._el.nativeElement.offsetParent.childNodes[2];

      // Listitem is not visible
      if (scrollingContainer.scrollTop > 0 && elementPosition < scrollingContainer.scrollTop + 60) {
        // top
        setTimeout(() => {
          this._el.nativeElement.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'start'
          });
        }, 1000);
      } else if (elementPosition > scrollingContainer.offsetHeight + scrollingContainer.scrollTop) {
        // bottom
        setTimeout(() => {
          this._el.nativeElement.scrollIntoView({
            block: 'end',
            behavior: 'smooth',
            inline: 'end'
          });
        }, 1000);
      }
    }
  }
}
