import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService, ThemeService, TranslationService } from '@assets/shared'
import { appSettings } from '../settings/app-settings';
import { DateTimeService } from 'eshava-controls';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  public isInitialized = false;

  constructor(
    private _authenticationService: AuthenticationService,
    private _themeService: ThemeService,
    private _translationService: TranslationService,
    private _dateTimeService: DateTimeService
  ) {
    this._themeService.init();
    this._authenticationService.configure(appSettings.authentication.oidc);
    this.isInitialized = this._authenticationService.isInitialized();
    this._subscriptions.add(this._authenticationService.profileLoaded$.subscribe(profile => {
      this.isInitialized = true;
      this._translationService.init((profile?.PreferredLanguage || '').split('-')[0] || 'de').then(_ => {

        this._dateTimeService.monthTranslations.january = this._translationService.translate('January', 'calendar');
        this._dateTimeService.monthTranslations.february = this._translationService.translate('February', 'calendar');
        this._dateTimeService.monthTranslations.march = this._translationService.translate('March', 'calendar');
        this._dateTimeService.monthTranslations.april = this._translationService.translate('April', 'calendar');
        this._dateTimeService.monthTranslations.may = this._translationService.translate('May', 'calendar');
        this._dateTimeService.monthTranslations.june = this._translationService.translate('June', 'calendar');
        this._dateTimeService.monthTranslations.july = this._translationService.translate('July', 'calendar');
        this._dateTimeService.monthTranslations.august = this._translationService.translate('August', 'calendar');
        this._dateTimeService.monthTranslations.september = this._translationService.translate('September', 'calendar');
        this._dateTimeService.monthTranslations.october = this._translationService.translate('October', 'calendar');
        this._dateTimeService.monthTranslations.november = this._translationService.translate('November', 'calendar');
        this._dateTimeService.monthTranslations.december = this._translationService.translate('December', 'calendar');

        this._dateTimeService.weekdayTranslations.monday = this._translationService.translate('MondayShort', 'calendar');
        this._dateTimeService.weekdayTranslations.tuesday = this._translationService.translate('TuesdayShort', 'calendar');
        this._dateTimeService.weekdayTranslations.wednesday = this._translationService.translate('WednesdayShort', 'calendar');
        this._dateTimeService.weekdayTranslations.thursday = this._translationService.translate('ThursdayShort', 'calendar');
        this._dateTimeService.weekdayTranslations.friday = this._translationService.translate('FridayShort', 'calendar');
        this._dateTimeService.weekdayTranslations.saturday = this._translationService.translate('SaturdayShort', 'calendar');
        this._dateTimeService.weekdayTranslations.sunday = this._translationService.translate('SundayShort', 'calendar');
      });
    }));
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
